import { useDebounceFn } from '@vueuse/core';
import BaseCollection from '~~/app/base/collections/BaseCollection';
import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import EndpointFactory from '~~/app/factories/EndpointFactory';
import { QueryParameter } from '~~/utils/reactiveQueryParameter';

export default function useCollectionLoader<M extends BaseModel, C extends BaseCollection<M>>(
    modelType: ModelType,
    filtersItems?: Array<QueryParameter>,
    endpointParamsCallback?: Function,
    onLoadedCallback?: Function
) {
    const collection = reactiveCollection<M, C>(null);
    const loading = ref(true);
    const abortController: Ref<AbortController | null> = ref(null);
    const endpoint = EndpointFactory.make(modelType);

    const filters = reactiveQueryParameters(useRouter(), useRoute(), filtersItems ?? [], () => load());

    const loadDebounced = useDebounceFn(() => load(), 250);

    const load = async (minimumDuration = 0) => {
        loading.value = true;

        console.log('loading');
        if (abortController.value) {
            console.log('aborting');
            abortController.value.abort();
        }

        abortController.value = new AbortController();

        const response = await Promise.all([endpoint.withAbortController(abortController).index(endpointParamsCallback ? endpointParamsCallback(filters) : {}), wait(minimumDuration)]).then(
            (values) => values[0]
        );

        const route = useRoute();

        if (response.error) {
            const isUnauthorizedOnDashboard = response?.response?.status === 403 && route.fullPath === '/dashboard';
            if (isUnauthorizedOnDashboard) return;

            if (response.response.status === 401) {
                // TBD IF THIS IS A SOLUTION
                useToasteoError('toasts.unauthorized');
                return;
            }

            useToasteoError('toasts.list_error');
            return;
        }

        let collectionLoaded = response.data;
        if (onLoadedCallback) {
            collectionLoaded = onLoadedCallback(response.data);
        }

        collection.value = collectionLoaded;

        loading.value = false;
    };

    return {
        collection,
        loading,
        load,
        loadDebounced,
        filters,
    };
}
